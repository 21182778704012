/* You can add global styles to this file, and also import other style files */

 @import '~quill/dist/quill.core.css';
 @import '~quill/dist/quill.bubble.css';
 @import '~quill/dist/quill.snow.css';

body {
  font-family: "Arial", Helvetica, Arial, serif;
}

.form-control {
  border-radius: 5px;
  border: solid 1px #e1e1e1;
  background-color: #ffffff;
  color: #999 !important;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c1c1c1;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c1c1c1;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c1c1c1;
}

.badge-primary {
  background-color: #116527 !important;
}

.btn {
  border-radius: 10px;
}

.btn-primary {
  background-color: #116527 !important;
  border: 0;
}

.btn-primary:hover {
  background-color: #116527 !important;
}

.btn-secondary {
  background-color: #f1f1f1 !important;
  color: #116527 !important;
  border: 0;
}

.btn-query-clear {
  float: right;
  right: 10px;
  position: absolute;
}

.btn-edit-secondary {
  padding: 5px 20px;
}

a {
  color: rgba(112, 83, 211, 1);
  font-size: 18px;
  text-decoration: underline;
}

.logo-img-brand {
  width: 106px !important;
  left: 30px;
  top: 20px;
}

.container-auth {
  width: 100%;
  height: 100%;
  // background-color: #000000;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .card-title {
    border-bottom: solid 1px #ccc;
    margin-bottom: 15px !important;
    text-align: center;
    font-weight: 700;

    p {
      font-weight: 100;
      font-size: 18px;
      margin-top: 20px;
    }
  }

  .card-body {
    padding: 10px 40px;
  }

  .card {
    border-radius: 0px;
  }

  .btn-block {
    margin-top: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }


}

.alert {
  border-radius: 0px;
}

html,
body {
  height: 100%;
}

h4 {
  margin-bottom: 30px;
}

.my-login-page {
  font-size: 14px;
}

.my-login-page .brand {
  width: 106px;
  overflow: hidden;
  margin: 0 auto;
  margin: 40px auto;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
}

.my-login-page .brand img {
  width: 100%;
}

.my-login-page .card-wrapper {
  width: 600px;
}

.my-login-page .card {
  border-color: transparent;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.05);
}

.my-login-page .card.fat {
  padding: 10px;
}

.my-login-page .card .card-title {
  margin-bottom: 30px;
}

.my-login-page .form-control {
  border-width: 2.3px;
}

.my-login-page .form-group label {
  width: 100%;
}

.my-login-page .btn.btn-block {
  padding: 12px 10px;
}

.my-login-page .margin-top20 {
  margin-top: 20px;
}

.my-login-page .no-margin {
  margin: 0;
}

.my-login-page .footer {
  margin: 40px 0;
  color: #888;
  text-align: center;
}

.text-bottom-link {
  color: #999;
  font-size: 18px;
}

select {
  width: 100%;
  padding: 5px 10px 5px 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 5px;
  height: 34px;
  color: #999 !important;
  // background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #FFFFFF;
  // background-position: 92% 51%;
  -webkit-appearance: none;
  outline: none;
}

select:focus,
select.form-control:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="text"].box-body-workout-inputs:focus,
[type="text"].box-body-exercice-inputs:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  outline: none;
  box-shadow: inset 0 -1px 0 #ddd;
}

// Dragula Start
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
// Dreagula end

.checkbox label:after,
.radio label:after {
  content: "";
  display: table;
  clear: both;
}

.checkbox .cr,
.radio .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: 0.5em;
  background-color: #ffffff;
}

.radio .cr {
  border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
  position: absolute;
  font-size: 0.8em;
  line-height: 0;
  top: 50%;
  left: 20%;
}

.radio .cr .cr-icon {
  margin-left: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
  opacity: 0.5;
}

.checkbox.float-left {
  float: left !important;
  position: absolute;
}

datatable-body-cell,
ngx-datatable {
  overflow: visible !important;
}
.dropdown-toggle::after {
  content: initial;
}

/* Spinner */

.spinner-ctnr {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  .spinner.colorwheel {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 100%;
    z-index: 0;
    // margin-top: 15%;
    // margin-left: 45%;
  }
  .spinner.colorwheel::before,
  .spinner.colorwheel::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .spinner.colorwheel::after {
    background: #f1f1f1;
    -webkit-animation: rotate 1.5s linear reverse infinite;
    animation: rotate 1.5s linear reverse infinite;
    // -webkit-animation: fade 1s infinite alternate, rotate 2.5s linear reverse infinite;
    // animation: fade 1s infinite alternate, rotate 2.5s linear reverse infinite;
  }

  .spinner .centerpiece {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 100%;
    border-color: #000;
    box-sizing: border-box;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #116527;
    border-top: 12px solid #116527;
    -webkit-animation: rotate 0.8s linear infinite;
    animation: rotate 0.8s linear infinite;
  }
  .logo-fixed {
    background-image: url("/assets/img/logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 94px;
    height: 138px;
    display: inline-block;
    z-index: 100;
    left: 0;
    right: 0;
    top: 46px;
    bottom: 0;
    margin: auto;
    position: absolute;
  }
}

@media (max-width: 768px) {
  .spinner-ctnr {
    .spinner.colorwheel {
      // margin-top: 15%;
      // margin-left: 35%;
    }
  }
}

// @-webkit-keyframes fade {
//   from { opacity: 1; }
//   to { opacity: 0.2; }
// }
// @keyframes fade {
//   from { opacity: 1; }
//   to { opacity: 0.2; }
// }

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Spinner end */

.list-group-item-step {
  border-left: solid 3px #ff0000;
  margin-bottom: 20px;
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 26px 23px;
  font-size: 14pt;
  font-weight: 500;
}

.ng-autocomplete {
  width: auto !important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #116527;
}

input:focus + .slider {
  box-shadow: 0 0 1px #116527;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

label {
  font-weight: 500;
}

.form-check-label {
  font-weight: 100;
}

.nav-tabs .nav-link.active {
    border-top: solid 3px #116527;
    color: #116527;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.modal {
    top: -10px;
}
// fix modal responsive
@media screen and (max-width: 640px) {
  .modal {
    width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
      max-width: 100%;
      height: 100%;
  }
}

